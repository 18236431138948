export default {
  FIRST_NAME: {
    label: 'Имя',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Имя',
  },
  LAST_NAME: {
    label: 'Фамилия',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Фамилию',
  },
  SECOND_NAME: {
    label: 'Отчество (если есть)',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Отчество',
  },
  GENDER: {
    label: 'Пол',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'M', nameOption: 'Мужчина'},
      {valueOption: 'F', nameOption: 'Женщина'}]
  },
  LONGATION: {
    label: 'Согласен с условиями пролонгации займа, ',
    info: '',
    mask: '',
    type: ''
  },
  EXPER_RADIO: {
    label: 'Стаж на последнем месте работы',
    placeholder:'Выбрать',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Не работаю', nameOption: 'Не работаю'},
      {valueOption: 'До 3 мес.', nameOption: 'До 3 мес.'},
      {valueOption: 'От 3 мес. до года', nameOption: 'От 3 мес. до года'},
      {valueOption: 'От года до 3-х лет', nameOption: 'От года до 3-х лет'},
      {valueOption: 'От 3-х до 5-ти лет', nameOption: 'От 3-х до 5-ти лет'},
      {valueOption: 'От 5-ти до 10-ти лет', nameOption: 'От 5-ти до 10-ти лет'},
      {valueOption: 'Свыше 10-ти лет', nameOption: 'Свыше 10-ти лет'}
    ]
  },
  CREDHISTORY_RADIO: {
    label: 'Кредитная история',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Нет, кредиты не брал', nameOption: 'Нет, кредиты не брал'},
      {valueOption: 'Да, есть погашенные кредиты', nameOption: 'Да, есть погашенные кредиты'},
      {valueOption: 'Да, есть непогашенные кредиты', nameOption: 'Да, есть непогашенные кредиты'}
    ]
  },
  CALC_SUMM: {
    label: 'Сумма займа',
    info: '',
    mask: '',
    type: '',
    selectOption: [{valueOption: 20000, nameOption: 20000},
      {valueOption: 21000, nameOption: 21000},
      {valueOption: 22000, nameOption: 22000},
      {valueOption: 23000, nameOption: 23000},
      {valueOption: 24000, nameOption: 24000},
      {valueOption: 25000, nameOption: 25000},
      {valueOption: 26000, nameOption: 26000},
      {valueOption: 27000, nameOption: 27000},
      {valueOption: 28000, nameOption: 28000},
      {valueOption: 29000, nameOption: 29000},
      {valueOption: 30000, nameOption: 30000},
      {valueOption: 31000, nameOption: 31000},
      {valueOption: 32000, nameOption: 32000},
      {valueOption: 33000, nameOption: 33000},
      {valueOption: 34000, nameOption: 34000},
      {valueOption: 35000, nameOption: 35000},
      {valueOption: 36000, nameOption: 36000},
      {valueOption: 37000, nameOption: 37000},
      {valueOption: 38000, nameOption: 38000},
      {valueOption: 39000, nameOption: 39000},
      {valueOption: 40000, nameOption: 40000},
      {valueOption: 41000, nameOption: 41000},
      {valueOption: 42000, nameOption: 42000},
      {valueOption: 43000, nameOption: 43000},
      {valueOption: 44000, nameOption: 44000},
      {valueOption: 45000, nameOption: 45000},
      {valueOption: 46000, nameOption: 46000},
      {valueOption: 47000, nameOption: 47000},
      {valueOption: 48000, nameOption: 48000},
      {valueOption: 49000, nameOption: 49000},
      {valueOption: 50000, nameOption: 50000}
    ]
  },
  BIRTHDAY: {
    label: 'Дата рождения',
    info: '',
    mask: '',
    type: 'date'
  },
  BIRTHPLACE: {
    label: 'Место рождения',
    info: '',
    mask: '',
    type: ''
  },
  CALC_WEEK: {
    label: 'Срок займа',
    info: '',
    mask: '',
    type: ''
  },
  CONST_INCOME: {
    label: 'Сумма постоянного дохода / пенсии в месяц (руб.):',
    placeholder:'Введите сумму',
    info: '',
    mask: '##############',
    type: ''
  },
  EMAIL: {
    label: 'Email',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите E-mail'
  },
  IS_ADDRESS_COINCIDES: {
    label: 'Адрес регистраци совпадает с адресом проживания',
    info: '',
    mask: '',
    type: ''
  },
  IS_I_CERTIFY_REGISTRATION: {
    label: 'Нажимая кнопку вы подтверждаете свое согласие на обработку ваших персональных данных',
    info: '',
    mask: '',
    type: ''
  },
  MEETING_DATE: {
    label: 'Желаемая дата встречи с менеджером',
    info: '',
    mask: '',
    type: 'date',
  },
  MEETING_HOUR: {
    label: 'Желаемое время встречи с менеджером',
    info: '',
    mask: '',
    type: 'time'
  },
  OTHER_SOURCES_OF_INCOME: {
    label: 'Сумма дополнительного дохода в месяц (руб.):',
    placeholder:'Введите сумму',
    info: '',
    mask: '##############',
    type: ''
  },
  PASSPORT_DATE: {
    label: 'Дата выдачи паспорта',
    info: '',
    mask: '',
    type: 'date'
  },
  PASSPORT_DEP: {
    label: 'Кем выдан',
    placeholder:'Выберите из выпадющего списка или введите точно как в паспорте',
    info: '',
    mask: '',
    type: ''
  },
  PASSPORT_DEP_CODE: {
    label: 'Код подразделения',
    info: '',
    mask: '###-###',
    type: '',
    placeholder: '___-___',
  },
  PASSPORT_NUMBER: {
    label: 'Номер паспорта',
    info: '',
    mask: '######',
    type: '',
    placeholder:'______',
  },
  PASSPORT_SERIES: {
    label: 'Серия паспорта',
    info: '',
    mask: '####',
    type: '',
    placeholder:'____',
  },
  PHONE: {
    label: 'Телефон для связи',
    info: '',
    mask: '+7 (###) ###-##-##',
    placeholder: 'Введите телефон',
    type: ''
  },
  PRODUCT_ID: {
    label: 'Тип продукта',
    info: '',
    mask: '',
    type: ''
  },
  REGISTRATION_ADDRESS: {
    label: 'Адрес регистрации',
    placeholder:'Область, город, улица, дом, кв',
    info: '',
    mask: '',
    type: ''
  },
  REGISTRATION_ADDRESS_DADATA: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  RESIDENTIAL_ADDRESS: {
    label: 'Фактический адрес проживания',
    placeholder:'Область, город, улица, дом, кв',
    info: '',
    mask: '',
    type: ''
  },
  RESIDENTIAL_ADDRESS_DADATA: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  WORK_JOB: {
    label: 'Наименование организации:',
    placeholder:'Например, ИП Иванов',
    info: '',
    mask: '',
    type: ''
  },
  null: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  finderFio:{
    label: 'Поиск по ФИО клиента',
    info: '',
    mask: '',
    type: ''
  },
  finderPhone:{
    label: 'Поиск по номеру телефона',
    info: '',
    mask: '+7 (###) ###-##-##',
    type: ''
  },
  WHERE_TO_FIND_US:{
    label: 'Откуда о нас узнали',
    info: '',
    mask: '',
    type: '',
    selectOption:[
      {valueOption: 'Социальные сети', nameOption: 'Социальные сети'},
      {valueOption: 'и куча другого в справочнике', nameOption: 'и куча другого в справочнике'},
  ]
  },
  ReCallDate:{
    label: 'Дата звонка',
    info: '',
    mask: '',
    type: 'date',
    selectOption:[]
  },
  ReCallTime:{
    label: 'Время звонка',
    info: '',
    mask: '##:##',
    type: 'time',
    selectOption:[]
  },
  CAR_NUMBER:{
    label: 'Гос. номер автомобиля',
    info: 'пример: х010хх777',
    mask: '',
    type: '',
  },
  SmsConfCode:{
    label: 'Код подтверждения',
    info: '',
    mask: '',
    type: 'tel',
  },
  TYPE_OF_EMPLOYMENT: {
    label: 'Вид занятости ',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Без официального трудоустройства', nameOption: 'Без официального трудоустройства'},
      {valueOption: 'Наемный сотрудник', nameOption: 'Наемный сотрудник'},
      {valueOption: 'и куча другого в справочнике', nameOption: 'и куча другого в справочнике'}
    ]
  },
  TYPE_OF_ORGANIZATION: {
    label: 'Тип организации',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Государственная', nameOption: 'Государственная'},
      {valueOption: 'Частная', nameOption: 'Частная'},
    ]
  },
  TYPE_OF_ACTIVITY: {
    label: 'Сфера деятельности',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Добывающая промышленность', nameOption: 'Добывающая промышленность'},
      {valueOption: 'Здравоохранение', nameOption: 'Здравоохранение'},
      {valueOption: 'и куча другого в справочнике', nameOption: 'и куча другого в справочнике'}
    ]
  },
  EDUCATION: {
    label: 'Образование',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Среднее/среднее специальное', nameOption: 'Среднее/среднее специальное'},
      {valueOption: 'Неполное высшее', nameOption: 'Неполное высшее'},
      {valueOption: 'Высшее', nameOption: 'Высшее'},
      {valueOption: 'Ученая степень', nameOption: 'Ученая степень'},
    ]
  },
  MARITAL_STATUS: {
    label: 'Семейное положение',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Замужем/женат', nameOption: 'Замужем/женат'},
      {valueOption: 'Не замужем/холост', nameOption: 'Не замужем/холост'},
      {valueOption: 'Разведен/разведена', nameOption: 'Разведен/разведена'},
    ]
  },
  WHERE_T_FIND_US:{
    label: 'Откуда о нас узнали',
  }
}
