<template>
  <div class="checkBoxWrap">
    <label>
      <div class="checkBoxHint">
        <input type="checkbox"
               :true-value="trueValue"
               :false-value="falseValue"
               :name="name"
               :id="name"
               v-model="value"
        >
        <div class="visualCheckBox" v-bind:class="{active:isTrue}">
          <svg viewBox="0 0 12.828 8.414" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.414 1.414l-6.251 6-3.749-3.6" fill="none" stroke="rgba(13, 87, 64, 1)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/></svg>
        </div>
      </div>
      {{item.label}}
    </label>
  </div>

</template>

<script>
  import {mapState} from 'vuex';
  import DataForInput from '@/store/Form/longFormOptions';
  export default {
    name: "fmCheckBox",
    data(){
      return {
        item:DataForInput[this.name],
      }
    },
    components:{
    },
    computed:{
      isTrue(){
        return this.value == this.trueValue;
      },
      ...mapState({
        statusVuex: state => state.Form.workData,
        valueVuex: state => state.Form.sendData,
      }),
      value: {
        get() {
          return this.valueVuex[this.name]
        },
        set(value) {
          let utiputi = {
            target: this.name,
            value: value
          };
          this.$store.dispatch('saveSendData', utiputi);
        }
      }
    },
    props:{
      name:{
        type:String,
        default:'',
      },
      trueValue:{
        type:String,
        default: 'true'
      },
      falseValue:{
        type:String,
        default: 'false'
      },
    }
  }
</script>

<style scoped>
.checkBoxWrap{position: relative;background: rgba(255, 0, 0, 0.0);transition: 0.4s ease-out}
.checkBoxWrap.fail{background: rgba(255, 0, 0, 0.5);}
label{display: flex;align-items: center;justify-content: center;cursor: pointer; font-size: 2.06vw; color: #828282;}
label input{opacity: 0}
.visualCheckBox{width: 3.82vw;height: 3.82vw;display: flex;align-items: center;justify-content: center;border-radius: 2px; background: rgb(235 234 234) }
.visualCheckBox svg{width: 3.2vw;height: 1.9vw;opacity: 0;}
.visualCheckBox.active svg{opacity: 1;}
.checkBoxHint{position: relative;width: 3.82vw;height: 3.82vw; margin-right: 2vw }
.checkBoxHint > * {position: absolute;top:0;left:0}

@media screen and (min-width: 760px) {
  .visualCheckBox, .checkBoxHint{width: 1.6vw;height: 1.6vw;}
  .checkBoxHint{margin-right: 1vw}
  .visualCheckBox svg{    width: 0.9vw;height: 0.6vw;}
}
@media screen and (min-width: 1200px) {
  .visualCheckBox, .checkBoxHint{width: 18px;height: 18px;}
  .checkBoxHint{margin-right: 10px;}
  .visualCheckBox svg{width: 10px;height: 6px;}
  label { font-size: 10px }
}
</style>
